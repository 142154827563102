<template>
	<div class="sorting">
		<tk-drop-down>
			<template v-slot:toggle>
				<button type="button" class="sorting__toggle">
					{{ selectedSort.title || 'Сортировка' }}
				</button>
			</template>

			<template v-slot:content>
				<div class="sorting__content">
					<ul class="sorting__list">
						<li
							v-for="(item, index) in list"
							:key="index"
							class="sorting__item sort-item"
						>
							<button
								type="button"
								@click="change(item)"
								:class="[
									'sort-item__button',
									item.dir === 'asc' && 'sort-item__button--dir',
									isActive(item) && 'sort-item__button--active'
								]"
							>
								{{ item.title }}
								<tk-svg
									class="sort-item__button--icon sort-button-icon"
									type="arrow-top"
								/>
							</button>
						</li>
					</ul>
				</div>
			</template>
		</tk-drop-down>
	</div>
</template>

<script>
const list = [
	{
		title: 'По дате создания',
		dir: 'desc',
		key: 'created_at'
	},
	{
		title: 'По дате создания',
		dir: 'asc',
		key: 'created_at'
	},
	// {
	// 	title: 'По рейтингу',
	// 	dir: 'desc',
	// 	key: 'rating'
	// },
	// {
	// 	title: 'По рейтингу',
	// 	dir: 'asc',
	// 	key: 'rating'
	// },
	{
		title: 'По цене',
		dir: 'desc',
		key: 'price'
	},
	{
		title: 'По цене',
		dir: 'asc',
		key: 'price'
	},
	// {
	// 	title: 'По удаленности',
	// 	dir: 'desc',
	// 	key: 'distance'
	// },
	// {
	// 	title: 'По удаленности',
	// 	dir: 'asc',
	// 	key: 'distance'
	// },
	// {
	// 	title: 'По популярности',
	// 	dir: 'desc',
	// 	key: 'popular'
	// },
	// {
	// 	title: 'По популярности',
	// 	dir: 'asc',
	// 	key: 'popular'
	// }
]

export default {
	data() {
		return {
			selectedSort: {}
		}
	},
	beforeMount() {
		if (this.$route.query.sort) {
			const findSortItem = this.list.find(
				(itm) => itm.key === this.$route.query.sort && itm.dir === this.$route.query.dir
			)

			if (findSortItem) {
				this.selectedSort = findSortItem
			}
		}
	},
	computed: {
		list() {
			return list
		}
	},
	methods: {
		isActive(payload) {
			return (
				this.selectedSort.key === payload.key &&
				this.selectedSort.dir === payload.dir
			)
		},
		change(payload) {
			this.selectedSort = payload

			this.$router.replace({
				query: { ...this.$route.query, sort: payload.key, dir: payload.dir }
			})

			this.$emit('update', payload)
		}
	}
}
</script>