<template>
	<section class="search-place" :class="{ 'search-place--list' : !mapActive }">
		<div class="search-place__container" :class="{ enabled: mapActive }">
			<div class="search-place__wrapper">
				<div
					class="search-place__content search-place-content"
					:class="{ fullscreen: !mapActive }"
				>
					<div class="search-place__title">
						<div class="search-place__title-text">
							<span class="search-place__intro">
								Найдено Теремков: {{ places?.meta?.total }}
							</span>

							<div class="search-place__title-container">
								<h2 class="section-title">Жилье в этой области</h2>

								<Sorting
									v-if="!isEmpty"
									class="search-place__sorting"
									@update="sorting"
								/>
							</div>
						</div>

						<div class="search-place__title-btns search-btns">
							<button
								v-if="mapActive"
								class="btn search-place__filter-btn"
								type="button"
								@click="showPlaceFilter"
							>
								<tkSvg v-slot[icon-before] type="filter-btn" />
								Фильтр
							</button>

							<tk-button
								v-else
								type="button"
								class="search-btns__btn"
								@click="showPlaceFilter"
							>
								<tkSvg v-slot[icon-before] type="filter-btn" />
								Фильтр
							</tk-button>

							<tk-button
								v-show="!mapActive"
								class="search-btns__btn search-btns__btn--showmap"
								@click="toggleMap"
							>
								<tkSvg v-slot[icon-before] type="map-icon" />
								{{ btnText }}
							</tk-button>
						</div>
					</div>
          <div
            class="search-place__scroll"
            v-if="!isEmpty"
            @scroll="scrollLoad"
            ref="searchPlaceList"
          >
            <ul class="search-place__cards">
	            <li        v-for="place in places.items"
	                       :key="place.id">
		            <SearchCard

				            :place="place"
				            :class="{ fullscreen: !mapActive }"
				            @updatePlace="updatePlace"
		            />
	            </li>

            </ul>
            <div class="search-place__pagination">
              <tk-pagination
                  v-if="!mapActive"
                  :content="places?.meta"
                  :offset-page="query.page"
                  @next="changePage"
              />
            </div>
          </div>
					<p v-else class="search-place__empty">
						Извините, по выставленным фильтрам нет подходящего жилья.
						Рекомендуем сменить выбранный набор фильтров.
					</p>
          <button
						v-if="mapActive"
						@click="scrollToTop"
						class="search-place__up-btn"
						type="button"
					>
						<tkSvg v-slot[icon-before] type="arrow-top" />
						Вверх
					</button>

        </div>
        <div class="search-place__map" v-if="mapActive">
					<div class="search-place__map-btn">
						<tk-button
							type="button"
							@click="showPlaceFilter"
							class="search-place__filter-mob"
						>
							<tkSvg v-slot[icon-before] type="filter-btn" />
							Фильтр
						</tk-button>

						<tk-button
							kind="main-brown"
							class="search-place__close-btn"
							@click="toggleMap"
						>
							<tkSvg v-slot[icon-before] type="map-icon" />
							{{ btnText }}
						</tk-button>
					</div>

					<div class="search-place__map-container">
						<tk-ymaps
							v-if="!loading"
							:key="mapUpdateFlag"
							@update="update"
              @updatePlace="updatePlace"
							:center="center"
							:markers="markers"
							style="width: 100%; height: 100%;"
						/>
					</div>

					<tk-button class="closewmap" type="button" @click="toggleMap">
						Закрыть карту
					</tk-button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SearchCard from '@/components/cards/Search'
import Sorting from '@/components/Sorting'

import modals from '@/constants/modals'
import { GET, GET_ADDITIVE, GET_MARKERS } from "@/constants/store/search/actions";
import { MARKERS, CITY_CENTER } from '@/constants/store/search/getters'

// Refactoring classes and styles

let blockAdditiveLoad = false

export default {
	name: 'Place',
	components: { SearchCard, Sorting },
	data() {
		return {
			mapActive: true,
			searchPlaceList: null,
			loading: true,
      mapUpdateFlag: 0,
      queryChanged: false,
		}
	},
	async beforeMount() {
		this.loading = true
		await this.get(this.payloadForSearch)
		await this.getMarkers(this.payloadForSearch)
		await this.$nextTick(() => {
      this.loading = false
    })
	},
  watch: {
    '$route.query': async function () {
      this.queryChanged = true
      await this.updatePlace()
      this.queryChanged = false
    }
  },
	computed: {
		query() {
			return this.$route.query
		},
		payloadForSearch() {
			const payload = { ...this.$route.query }

			if (payload.options) {
				payload.options = JSON.parse(payload.options)
			}

      payload.per_page = 10
			return payload
		},
		btnText() {
			return this.mapActive ? 'Скрыть карту' : 'Показать карту'
		},
		places() {
			return this.$store.state.search.places
		},
		markers() {
			return this.$store.getters[MARKERS]
		},
		center() {
			return this.$store.getters[CITY_CENTER]
		},
		isEmpty() {
			return !this.places.items || !this.places.items.length
		}
	},
	methods: {
		async updatePlace() {
			await this.get(this.payloadForSearch)
			await this.getMarkers(this.payloadForSearch)
		},
		showPlaceFilter() {
			this.$store.commit('showModal', {
				name: modals.PLACE_FILTER,
				props: null,
				isShow: true
			})
		},
		async get(payload) {
			await this.$store.dispatch(GET, payload)
		},
    async getAdditive(payload) {
      await this.$store.dispatch(GET_ADDITIVE, payload)
    },
		async getMarkers(payload) {
      const oldMarkers = this.markers
			await this.$store.dispatch(GET_MARKERS, payload)
      if (oldMarkers.length !== this.markers.length || this.queryChanged) {
        this.mapUpdateFlag++
      }
		},
		toggleMap() {
			this.mapActive = !this.mapActive
		},
		scrollToTop() {
			this.$refs.searchPlaceList.scrollTop = 0
		},
    async changePage(page) {
      await this.get({ ...this.payloadForSearch, page })
    },
		async changePageScroll(page) {
			await this.getAdditive({ ...this.payloadForSearch, page })
		},
		async sorting() {
			// refactoring
			setTimeout(async () => {
				await this.get({ ...this.payloadForSearch })
			})
		},
		async update(evt) {
			await this.getMarkers({
				...this.payloadForSearch,
				radius: evt.distance,
				lat: evt.center[0],
				lng: evt.center[1]
			})
		},
		async scrollLoad(e) {
			const { scrollTop, clientHeight, scrollHeight } = e.target
			const { current_page, last_page } = this.places.meta

			if (
				scrollTop + clientHeight + 50 >= scrollHeight &&
				current_page < last_page && !blockAdditiveLoad
			) {
        blockAdditiveLoad = true
				await this.changePageScroll(this.places.meta.current_page + 1)
        blockAdditiveLoad = false
				// setTimeout(() => this.scrollToTop())
			}
		}
	}
}
</script>
