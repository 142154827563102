<template>
	<div class="search-card">
		<button
			class="btn search-card__fav-btn fav-btn"
			:class="{ active: place.is_favorite }"
			@click="setFavourite(place.id)"
      v-if="$store.getters.isAuth"
		>
			<tk-svg type="fav" />
		</button>

		<div class="search-card__photos place-gallery">
			<tk-carousel
				ref="searchCardCarousel"
				v-if="place.images?.length"
				:items-to-show="1"
			>
				<tk-slide
					v-for="(item, index) in place.images.map((itm) => itm.image)"
					class='test'
					:key="index"
				>
					<tk-image
						class="place-gallery__image place-image"
						width="200"
						height="150"
						:src="item"
					/>
				</tk-slide>
			</tk-carousel>
			<div class="place-gallery__btns">
				<button
					type="button"
					class="btn place-gallery__toggle prev"
					aria-label="Prev slide"
					@click="prev"
				>
					<tk-svg type="arrow-top" />
				</button>
				<button
					type="button"
					class="btn place-gallery__toggle next"
					aria-label="Next slide"
					@click="next"
				>
					<tk-svg type="arrow-top" />
				</button>
			</div>
		</div>

		<router-link
			:to="{ path: `/place/${place.id ?? place.place_id}`, query: { ...qrs } }"
			class="search-card__link place-link"
		>
			<div class="search-card__rating place-rating">
				<b>{{ place.rating.toFixed(1) }}</b>
				<span class="reviews-count"> ({{ place.reviews_count }}) </span>
			</div>
			<p class="search-card__name place-name">{{ place.title }}</p>
			<div class="search-card__price place-price">
        {{ place.price_type != 'place' ? 'От' : ''}} <b>{{ place.price }} р</b> за ночь
			</div>
		</router-link>
	</div>
</template>

<script>
// Refactoring css and styles

export default {
	props: {
		place: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		qrs() {
			let { city, guests, children, date_from, date_to, ...rest } = this.$route.query
      if (!date_from) {
        const today = new Date();
        date_from=`${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`
      }
      if (!date_to) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        date_to=`${tomorrow.getFullYear()}-${('0' + (tomorrow.getMonth() + 1)).slice(-2)}-${('0' + tomorrow.getDate()).slice(-2)}`
      }
      guests = +guests || 1
      children = +children || 0

			return {
        city,
        guests,
        children,
        date_to,
        date_from,
				...rest
			}
		}
	},
	methods: {
		next() {
			this.$refs.searchCardCarousel.next()
		},
		prev() {
			this.$refs.searchCardCarousel.prev()
		},
		async setFavourite() {
			let response

			if (!this.place.is_favorite) {
				response = await this.$api.favorites.create({
					places: this.place.id ?? this.place.place_id
				})
			} else {
				response = await this.$api.favorites.delete({
					place_id: this.place.id ?? this.place.place_id
				})
			}

			if (response?.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно'
				})

        this.$emit('updatePlace', { id: this.place.id })
			} else {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Ошибка запроса'
				})
			}
		}
	}
}
</script>

<style lang="sass">
.search-card
  position: relative
  display: grid
  padding: 20px 15px 20px 15px
  grid-template-columns: 200px 1fr
  grid-column-gap: 20px
  border: 1px solid transparent
  background-color: white
  border-top-color: #E2E2E2
  border-radius: 10px
  transition: 0.1s linear
  height: 100%
  &:hover
    border-color: #1AC386
    box-shadow: 0px 5px 15px rgba(95, 83, 80, 0.15)

  &__fav-btn
    position: absolute
    top: 30px
    right: 25px
    width: 20px
    height: 20px
    z-index: 3

    &:hover svg .active,
    &.active svg .active
      fill: #1AC386

  &__rating
    position: relative
    padding-left: 25px
    font-size: 14px
    line-height: 14px
    &:before
      content: ''
      position: absolute
      top: -6px
      left: 0
      width: 20px
      height: 20px
      background: url("~@/assets/img/svg/views/places/star.svg") no-repeat #ffffff
      background-size: 20px 20px

  .place-gallery
    position: relative
    width: 100%

    &__image
      width: 200px
      height: 150px
      border: 1px solid #E2E2E2
      border-radius: 10px
      overflow: hidden
      object-fit: cover
    &__btns
      position: absolute
      top: 0
      bottom: 0
      width: 100%
      padding: 0 10px
      display: flex
      justify-content: space-between
      align-items: center
      box-sizing: border-box

    &__toggle
      display: flex
      justify-content: center
      align-items: center
      width: 25px
      height: 25px
      border-radius: 50%
      background: rgba(26, 195, 134, 0.9)
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)
      overflow: hidden

      &.prev
        transform: rotate(-90deg)

      &.next
        transform: rotate(90deg)

  &__link
    padding: 20px 0
    display: flex
    flex-direction: column
    justify-content: center
    text-decoration: none
    color: #3B3F47
    cursor: pointer

  &__name
    margin-top: 10px
    margin-bottom: 25px
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 18px

  &__price
    font-style: normal
    font-size: 18px
    line-height: 18px

  &.fullscreen
    display: flex
    flex-direction: column
    gap: 0
    padding: 0
    background: #FFFFFF
    border: 0.5px solid #E2E2E2
    border-radius: 10px
    .place-gallery__image
      height: 150px
      @media screen and (max-width: 470px)
        height: 200px

    .fav-btn
      top: 10px
      right: 10px

      svg
        width: 15px
        height: 13px

    .place-link
      width: 100%
      padding: 10px 10px 15px 10px
      height: 100%

    .place-rating
      font-size: 12px
      line-height: 12px

    .place-name
      margin-bottom: 8px
      font-weight: 400
      font-size: 14px
      line-height: 16px

    .place-price
      font-size: 16px
      line-height: 16px
      margin-top: auto
      padding-top: 8px
      border-top: 0.5px solid #E2E2E2
  @media screen and (max-width: 470px)
    width: 100%
    padding: 0
    grid-template-columns: 100%
    border: 0.5px solid #E2E2E2

    .place-gallery
      width: 100%
      margin-left: 0 !important

    .place-image
      width: 100%
      height: auto
      min-height: 150px
      img
        width: 100%

    .place-link
      padding: 15px 10px !important

    .place-rating
      margin: 0 10px
      font-size: 12px
      line-height: 12px

    .place-name
      padding: 0 10px
      padding-bottom: 10px
      margin-bottom: 10px
      font-weight: 400
      font-size: 14px
      line-height: 16px

    .place-price
      padding: 0 10px
      font-size: 16px
      line-height: 16px
@media screen and (max-width: 1180px)
	.search-card
		display: block
		.place-gallery__image
			width: 100%
			max-width: 250px
			height: 200px

@media screen and (max-width: 800px)
	.search-card
		.place-gallery__image
      height: 400px
      max-width: 500px
		&__fav-btn
			top: unset
			bottom: 40px

@media screen and (max-width: 470px)
	.search-card
    &__fav-btn
      bottom: 10px
    .place-gallery__image
      height: 250px

</style>
